import * as Sentry from '@sentry/react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from 'components/Button/ButtonV2';
import LogoIcon from 'assets/icons/logo.svg';
import AuthUtils from 'utils/authUtils';
import Heading from 'components/Heading/HeadingV2';

import { clearInfo, setItem } from 'utils/storageUtils';
import InputV2 from 'components/V2/Input/InputV2';
import { sendMagicLink } from 'network/authentication';

const LoginPanel: React.FC = () => {
  const [loginInfo, setLoginInfo] = useState({
    email: '',
  });

  useEffect(() => {
    document.title = 'Login | Outdefine';
  }, []);

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email } = loginInfo;
    console.log(email);
    if (email) setItem('email', email);
    else clearInfo();
    setLoading(true);
    try {
      await sendMagicLink(email);
      toast.success("Magic link sent");
    } catch (err: any) {
      Sentry.captureException(err);
      console.log('login err: ', err);
      toast.error(AuthUtils.getAuthError(err) || "Error on magic link", { theme: 'colored' });
    }
    setLoading(false);
  };

  return (
    <div className='max-w-[275px] flex flex-col w-full md:w-[275px]'>
      <img src={LogoIcon} alt='Equi' width={42} className='hidden md:block' />
      <Heading
        className='font-bold text-[24px] text-black pt-6 pb-4 hidden md:block'
        variant='h5'
      >
        Admin Login
      </Heading>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <InputV2
          placeholder='Email'
          className='mt-3 font-inter text-xs'
          name='email'
          type='email'
          value={loginInfo.email}
          onChange={(e: any) =>
            setLoginInfo({ ...loginInfo, email: e.target.value })
          }
          required
        />
        <div className='flex items-center justify-center mt-4'>
          <Button type='submit' className='w-full' loading={isLoading}>
            Log in
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginPanel;
