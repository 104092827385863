import { API } from "aws-amplify";
import axios from "axios";

import awsUtils from "utils/awsUtils";
import { getItem } from "utils/storageUtils";

async function getRolesSkills() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/jobTypes`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result.data.all;
}

async function getClientProfile(clientId: number) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/${clientId}`;
  const myInit = {
    headers: { ...authHeader },
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function createFreelancerProfile(profile: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/profile`;
  const myInit = {
    headers: { ...authHeader },
    body: profile,
  };
  const result = await API.put(apiName, path, myInit);
  return result.all;
}

async function createUser(userInfo: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/user`;
  const myInit = {
    headers: { ...authHeader },
    body: userInfo,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function uploadResume(files, freelancer_id) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  let form = new FormData();
  form.append("content", files);
  form.append("freelancer_id", freelancer_id);
  const path = `/freelancer/profile/uploadResume/`;
  const myInit = {
    headers: { ...authHeader },
    body: form,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function uploadCoverPhoto(files) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  let form = new FormData();
  form.append("content", files);
  const path = `/freelancer/profile/portfolio/uploadCoverImage`;
  const myInit = {
    headers: { ...authHeader },
    body: form,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function updatePortfolio(portfolio) {
  const authHeader = await awsUtils.getAuthHeader();
  const { project_name, project_description, cover_images } = portfolio;
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/profile/portfolio`;
  const email = getItem(`email`);
  const portfolio_data = [
    {
      project_name,
      project_description,
      cover_images: JSON.stringify(cover_images),
    },
  ];
  const formData = {
    email_id: email,
    portfolios: portfolio_data,
  };
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(formData),
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function getUserDetail(emailId = "") {
  const authHeader = await awsUtils.getAuthHeader();
  const email = emailId || getItem("email");
  const apiName = awsUtils.getAPIName();
  const path = `/user/${email}`;
  const myInit = {
    headers: { ...authHeader },
  };

  const result = await API.get(apiName, path, myInit);
  return result;
}

async function updateSkills(skills) {
  const authHeader = await awsUtils.getAuthHeader();
  const user_detail = await getUserDetail();
  const apiName = awsUtils.getAPIName();
  const path = "/freelancer/profile/skill";
  const form = {
    freelancer_id: user_detail.data.user_id,
    skills: skills,
  };

  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(form),
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function updateUser(userInfo) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = "/user";
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(userInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function createClientProfile(clientProfileInfo) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = "/client/profile";
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(clientProfileInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function addClients(clientInfo) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = "/client/profile/addClients";
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(clientInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}


async function uploadUserAvatarLogo(files) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const email = getItem("email");
  let form = new FormData();
  form.append("content", files);
  const path = `/user/avatar/${email}`;

  const myInit = {
    headers: { ...authHeader },
    body: form,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function updateOnboardingStatus(stateInfo) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = "/client/profile/updateOnboardingStatus";
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(stateInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function talentStep1(dataObj: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/onboarding/step1`;
  const myInit = {
    headers: { ...authHeader },
    body: dataObj,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function talentStep2(dataObj: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/onboarding/step2`;
  const myInit = {
    headers: { ...authHeader },
    body: dataObj,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function talentStep3(dataObj: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/onboarding/step3`;
  const myInit = {
    headers: { ...authHeader },
    body: dataObj,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function talentStep4(dataObj: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/onboarding/step4`;
  const myInit = {
    headers: { ...authHeader },
    body: dataObj,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function talentStep5(dataObj: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/onboarding/step5`;
  const myInit = {
    headers: { ...authHeader },
    body: dataObj,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function talentStep6(dataObj: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/onboarding/step6`;
  const myInit = {
    headers: { ...authHeader },
    body: dataObj,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

async function uploadUserBanner(files) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const email = getItem("email");
  let form = new FormData();
  form.append("content", files);
  const path = `/user/banner/${email}`;

  const myInit = {
    headers: { ...authHeader },
    body: form,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

export default {
  getRolesSkills,
  getUserDetail,
  createFreelancerProfile,
  createUser,
  uploadResume,
  uploadCoverPhoto,
  updatePortfolio,
  updateSkills,
  updateUser,
  createClientProfile,
  addClients,
  uploadUserAvatarLogo,
  updateOnboardingStatus,
  getClientProfile,
  talentStep1,
  talentStep2,
  talentStep3,
  talentStep4,
  talentStep5,
  talentStep6,
  // james
  uploadUserBanner,
};
