import { API } from 'aws-amplify';
import axios from 'axios';
import awsUtils from 'utils/awsUtils';
import { getItem } from 'utils/storageUtils';

async function createUser(userInfo) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/user`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(userInfo),
    response: true,
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

export async function sendMagicLink(email: string) {
  const apiName = awsUtils.getAPIName();
  const path = `/auth/login/magiclink`;
  const myInit = {
    body: JSON.stringify({ email, app: "AdminFrontend" })
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

export default {
  createUser
};
