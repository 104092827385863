import { Auth } from "aws-amplify";
import Loader from "components/V2/Loader/Loader";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "redux/hooks/redux-hooks";
import { loadAuth } from "redux/slices/authentication";
import authUtils from "utils/authUtils";
import { toast } from "react-toastify";

// The purpose of this view is to analyze the current magic link in order to sign in the user
export const MagicLink = () => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const login = async (email: string, token: string) => {
    try {
      const cognitoUser = await Auth.signIn(email); // Start custom sign in
      await Auth.sendCustomChallengeAnswer(cognitoUser, token); // Send answer to magic link challenge
      dispatch(loadAuth());
    } catch (e) {
      console.log(JSON.stringify(e));
      toast.error(authUtils.getAuthError(e));
    }
    navigate("/");
  };

  // Allow to change tab from url
  useEffect(() => {
    const token = params.get("token") || "";
    const email = params.get("email") || "";
    login(email, token);
  }, [params]);

  return (
    <div className="h-screen">
      <Loader />
    </div>
  );
};


export default MagicLink;
