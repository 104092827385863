import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import Button from "components/Button/ButtonV2";
import ModalV2 from "components/Modal/ModalV2";
import { FormCheckBoxV2 } from "components/V2/Buttons/CheckBoxV2";
import { FormDialCodeInput } from "components/V2/DialCodeInput/FormDialCodeInput";
import { FormDropdownV2 } from "components/V2/Dropdown/FormDropdownV2";
import { FormControl } from "components/V2/Form/FormControl";
import Label from "components/V2/Form/Label";
import IconV2 from "components/V2/Icons/IconV2";
import { FormInputV2 } from "components/V2/Input/FormInputV2";
import { FormCityInputV2 } from "components/V2/LocationAutocomplete/CityInputV2";
import { FormCountryInputV2 } from "components/V2/LocationAutocomplete/CountryInputV2";
import { FormPhoneInput } from "components/V2/PhoneInput/FormPhoneInput";
import ENUMS from "constants/enums";
import { Company } from "types/Company";
import utils from "utils/utils";
import { dialCodes } from "components/V2/DialCodeInput/DialCodeInput";

const industryData = ENUMS.industry.map((value, id) => ({ id, value }))
const companyStageData = ENUMS.companyStage.map((value, id) => ({ id, value }));
const numberOfEmployeesData = ENUMS.companyNumberOfEmployees.map((value, id) => ({ id, value }));

const blueBadgeClass = "text-xs font-inter px-3 py-2 w-fit mt-2"

interface IEditCompany {
    isOpen: boolean,
    onClose: () => void,
    onSuccess: () => void,
    company: Company,
}

const EditCompanyModal = ({ isOpen, company, onClose, onSuccess, }: IEditCompany) => {

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            industry: industryData.find(i => i.value === company.industry)?.id,
            companyStage: companyStageData.find(i => i.value === company.stage)?.id,
            numberOfEmployees: numberOfEmployeesData.find(i => i.value === company.number_of_employees)?.id,
            isRemoteFirst: company.remote_first,
            city: company.city || undefined,
            country: company.country || undefined,
            phoneNumber: company.phone_number || undefined,
            dialCode: dialCodes.find(d => d.code === company.dial_code),
            companyWebsite: company.website || undefined,
            linkedin: company.CompanySocialLink?.linkedin_link,
            twitter: company.CompanySocialLink?.twitter_link,
            instagram: company.CompanySocialLink?.instagram_link
        },
    });

    const isRemoteFirst = useWatch({
        control,
        name: "isRemoteFirst",
    });



    return (
        <ModalV2 isOpen={isOpen}>
            <form className="w-[717px] pb-[20px] rounded-md px-[53px] mx-auto mt-[131px] bg-white">
                <div className="w-full pt-[27px]">
                    <div onClick={onClose} className="ml-auto mx-[-20px] w-fit hover:cursor-pointer ">
                        <IconV2 iconType="CLOSE" />
                    </div>
                </div>

                <div className="mx-auto w-fit mt-[13px]">
                    <span className="text-[20px] font-poppins font-semibold w-fit" >Edit company information</span>
                </div>
                <div>
                    <div className="flex justify-between w-full gap-x-[57px] mt-[65px]">
                        <FormControl>
                            <Label>Industry</Label>
                            <FormDropdownV2
                                disabled
                                name='industry'
                                control={control}
                                rules={{ required: true }}
                                icon={<IconV2 iconType="COMPANY" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                                data={industryData}
                                placeholder='Select your industry'
                                withBadge
                                normalCase
                                badgeClass={blueBadgeClass}
                            />
                            {errors?.industry && (
                                <span className='font-inter text-xs text-red'>
                                    Industry is required
                                </span>
                            )}
                        </FormControl>

                        <FormControl>
                            <Label>Company stage</Label>
                            <FormDropdownV2
                                disabled
                                name='companyStage'
                                control={control}
                                rules={{ required: true }}
                                data={companyStageData}
                                icon={<IconV2 iconType="COMPANY" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                                placeholder='Select company stage'
                                withBadge
                                normalCase
                                badgeClass={blueBadgeClass}
                            />
                            {errors?.companyStage && (
                                <span className='font-inter text-xs text-red'>
                                    Company stage is required
                                </span>
                            )}
                        </FormControl>
                    </div>

                    <FormControl className="mt-[34px] mb-[37px]">
                        <Label>Number of employees</Label>
                        <FormDropdownV2
                            disabled
                            name='numberOfEmployees'
                            control={control}
                            rules={{ required: true }}
                            icon={<IconV2 iconType="USER" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                            data={numberOfEmployeesData}
                            placeholder='Select number of employees'
                            withBadge
                            normalCase
                            badgeClass={blueBadgeClass}
                        />
                        {errors?.numberOfEmployees && (
                            <span className='font-inter text-xs text-red'>
                                Number of employees is required
                            </span>
                        )}
                    </FormControl>


                    <span className="font-semibold font-poppins text-[14px]">Location</span>


                    <div className="flex justify-between w-full gap-x-[57px] mt-[26px]">
                        <FormControl>
                            <Label htmlFor='city'>Country</Label>
                            <FormCountryInputV2
                                disabled
                                name='country'
                                control={control}
                                rules={{ required: !isRemoteFirst }}
                                placeholder='Type a country'
                                icon={<IconV2 iconType="LOCATION" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                                className='text-xs font-inter'
                                withBadge
                                badgeClass={blueBadgeClass}
                                directionUp
                            />
                            {!isRemoteFirst && errors?.country && (
                                <span className='font-inter text-xs text-red'>
                                    Country is required
                                </span>
                            )}
                        </FormControl>
                        <FormControl>
                            <Label htmlFor='country'>City</Label>
                            <FormCityInputV2
                                disabled
                                name='city'
                                control={control}
                                rules={{ required: !isRemoteFirst }}
                                placeholder='Type a city'
                                icon={<IconV2 iconType="LOCATION" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                                inputClass='text-xs font-inter'
                                withBadge
                                badgeClass={blueBadgeClass}
                                directionUp
                            />
                            {!isRemoteFirst && errors?.city && (
                                <span className='font-inter text-xs text-red'>
                                    City is required
                                </span>
                            )}
                        </FormControl>
                    </div>

                    <div className='mt-5'>
                        <FormCheckBoxV2 disabled name='isRemoteFirst' control={control} value=''>
                            <span className='leading-6 font-inter text-xs tracking-[-0.25px]'>
                                Remote first company
                            </span>
                        </FormCheckBoxV2>
                    </div>

                    <div className='flex justify-between w-full gap-x-[57px] mt-[26px]'>
                        <FormControl>
                            <Label>Dial code</Label>
                            <FormDialCodeInput
                                disabled
                                name='dialCode'
                                control={control}
                                rules={{ required: false }}
                                placeholder='US +1'
                            />
                        </FormControl>
                        <FormControl>
                            <Label>Phone number</Label>
                            <FormPhoneInput
                                disabled
                                name='phoneNumber'
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (phone) => {
                                        return utils.clientPhoneRegex.test(phone);
                                    },
                                }}
                                className='w-full h-12 text-xs font-inter'
                            />
                            {errors?.phoneNumber && (
                                <span className='font-inter text-xs text-red'>
                                    {String(errors?.phoneNumber?.type) === "required"
                                        ? "Phone number is required"
                                        : "Phone number format is invalid"}
                                </span>
                            )}
                        </FormControl>
                    </div>

                    <FormControl className="mt-[30px]">
                        <Label htmlFor='companyWebsite'>Company website</Label>

                        <FormInputV2
                            disabled
                            name='companyWebsite'
                            control={control}
                            rules={{
                                required: true,
                                validate: (url) => utils.urlRegex.test(url)
                            }}
                            icon={<IconV2 iconType="WORLD" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                            className='w-full h-12 text-xs font-inter'
                            placeholder='Website url'
                        />
                        {errors?.companyWebsite && (
                            <span className='font-inter text-xs text-red'>
                                {String(errors?.companyWebsite?.type) === "required"
                                    ? "Website URL is required"
                                    : "Website URL is invalid (e.g. https://example.com)"}
                            </span>
                        )}
                    </FormControl>

                    <div className="mt-[38px]">
                        <span className="font-semibold font-poppins text-[14px]">Socials</span>

                        <FormControl>
                            <FormInputV2
                                disabled
                                name="linkedin"
                                control={control}
                                className="mt-[14px]"
                                placeholder="Linkedin url"
                                icon={<IconV2 iconType="LINKEDIN" iconClassName="w-[40px] h-[40px] mt-[15px] pr-[20px]" />}
                                rules={{
                                    required: false,
                                    validate: (url) => url ? utils.linkedinRegex.test(url) : true
                                    ,
                                }}
                            />
                            {errors?.linkedin?.type === "required" && (
                                <p className="font-inter text-xs text-red mt-1">{"Please fill in this field"}</p>
                            )}
                            {errors?.linkedin?.type === "validate" && (
                                <p className="font-inter text-xs text-red mt-1">
                                    {"Linkedin profile is invalid (e.g. https://linkedin.com/in/john-doe)"}
                                </p>
                            )}
                        </FormControl>

                        <FormControl>
                            <FormInputV2
                                disabled
                                name="twitter"
                                control={control}
                                className="mt-[14px]"
                                placeholder="Twitter url"
                                icon={<IconV2 iconType="TWITTER" iconClassName="w-[40px] h-[40px] mt-[15px] pr-[20px]" />}
                                rules={{
                                    required: false,
                                    validate: (url) => url ? utils.urlRegex.test(url) : true
                                    ,
                                }}
                            />
                            {errors?.twitter && (
                                <p className="font-inter text-xs text-red mt-1">{"Twitter URL is invalid (e.g. https://example.com)"}</p>
                            )}
                        </FormControl>

                        <FormControl>
                            <FormInputV2
                                disabled
                                name="instagram"
                                control={control}
                                className="mt-[14px]"
                                placeholder="Instagram url"
                                icon={<IconV2 iconType="INSTAGRAM" iconClassName="w-[40px] h-[40px] mt-[15px] pr-[20px]" />}
                                rules={{
                                    required: false,
                                    validate: (url) => url ? utils.urlRegex.test(url) : true
                                    ,
                                }}
                            />
                            {errors?.twitter && (
                                <p className="font-inter text-xs text-red mt-1">{"Instagram URL is invalid (e.g. https://example.com)"}</p>
                            )}
                        </FormControl>
                    </div>
                </div>

                <div className="flex justify-center w-full">
                    <Button type="submit" className="mt-[20px]" loading={isSubmitting} disabled>
                        Save changes
                    </Button>
                </div>
            </form>
        </ModalV2 >
    );
}

export default EditCompanyModal;